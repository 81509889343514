import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/zollinger/zollinger.png"
import zollinger1 from "../../../assets/images/portfolio_items/zollinger/zollinger1.png"
import zollinger2 from "../../../assets/images/portfolio_items/zollinger/zollinger2.png"
import zollinger3 from "../../../assets/images/portfolio_items/zollinger/zollinger3.png"

export default () => 
<div>
    <SEO title={'alt="Sigma Aldrich, ICON Worldwide, print and digital design strategy, portfolio" title="ICON Worldwide portfolio, print and digital design strategy, Sigma Aldrich" Stiftung'} 
    description="A straightforward Drupal integrated platform for a unique healthcare facility: Zollinger Stiftung. Discover more on ICON's portfolio! "
    canonical={'https://icon-worldwide.com/works/zollinger-stiftung'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Zollinger Stiftung, ICON Worldwide, Drupal integrated platform, portfolio" title="ICON Worldwide portfolio, Drupal integrated platform, Zollinger Stiftung"/>
            <div className="title"><h1>Zollinger Stiftung<span></span></h1>
            <h2>Drupal integrated platform</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Zollinger is a unique healthcare facility. It combines a luxury, independent living facility, with a complete hospital on a beautiful campus. We created the integrated platform in Drupal.</p>
        <div className="portfolio-images">
            <img src={zollinger1} alt="Zollinger Stiftung, ICON Worldwide, Drupal integrated platform, portfolio" title="ICON Worldwide portfolio, Drupal integrated platform, Zollinger Stiftung"/>
            <div></div>
            <img src={zollinger2} alt="Zollinger Stiftung, ICON Worldwide, Drupal integrated platform, portfolio" title="ICON Worldwide portfolio, Drupal integrated platform, Zollinger Stiftung"/>
            <img src={zollinger3} alt="Zollinger Stiftung, ICON Worldwide, Drupal integrated platform, portfolio" title="ICON Worldwide portfolio, Drupal integrated platform, Zollinger Stiftung"/>
        </div>
        <WorkFooter previous="sigma-aldrich" next="residenz-forch"/>
        </div>
    </div>
</div>